import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { EventCardComponent, EventVideoDialog } from './event-card/event-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatToolbarModule,MatCardModule,MatGridListModule, MatSidenavModule, MatDialogModule, 
        MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule,
        MatSelectModule, MatExpansionModule} from '@angular/material';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { MatVideoModule } from 'mat-video';

import { registerLocaleData } from '@angular/common';
import localenz from '@angular/common/locales/en-NZ'

registerLocaleData(localenz);

import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    EventCardComponent,
    EventVideoDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'monitor-app'),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatCardModule,
    MatGridListModule,
    MatSidenavModule,
    MatDialogModule,
    MatVideoModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    LazyLoadImageModule
  ],
  providers: [{ provide: StorageBucket, useValue: 'macpherson-video-processed' },
                {provide: MAT_DATE_LOCALE, useValue: 'en-NZ'}],
  bootstrap: [AppComponent],
  entryComponents: [EventVideoDialog]
})
export class AppModule { 

}
