import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable} from 'rxjs';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface Event {

    bucket: string;
    camera: string;
    creationTimestamp: firebase.firestore.Timestamp;
    day: string;
    duration: number;
    endDatetime: Date;
    endTime: string;
    fileName: string;
    id: string;
    month: string;
    originalBucket: string;
    originalFileName: string;
    originalFormat: string;
    startDatetime: firebase.firestore.Timestamp;
    startTime: string;
    thumbnail: string;
    year: string;
}

export interface DialogData {
    videoUrl: Observable<string | null>;
}


@Component({
    selector: 'app-event-card',
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {

    thumbNailUrl: Observable<string | null>;
    videoUrl: Observable<string | null>;

    _event: Event;

    @Input()
    set event(event: Event) {
        this._event = event;
        const ref = this.storage.ref(this._event.thumbnail);
        this.thumbNailUrl = ref.getDownloadURL();

        const videoRef = this.storage.ref(this._event.fileName);
        this.videoUrl = videoRef.getDownloadURL();
    }

    get event(): Event { return this._event; }

    constructor(private storage: AngularFireStorage, public dialog: MatDialog) {
    }

    ngOnInit() {
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(EventVideoDialog, {
            data: { videoUrl: this.videoUrl }
        });

    }

}

@Component({
    selector: 'event-video-dialog',
    templateUrl: './event-video-dialog.html',
})
export class EventVideoDialog {

    dialogVideoUrl: Observable<string | null>;;

    constructor(
        public dialogRef: MatDialogRef<EventVideoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.dialogVideoUrl = data.videoUrl;
    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

}