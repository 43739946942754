import { Component, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {FormControl} from '@angular/forms';

import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { auth } from 'firebase';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'monitor-app';
    events: Observable<any[]>;

    cameraFilter$: BehaviorSubject<string | null>;
    dateFilter$: BehaviorSubject<Date | null>;

    db: AngularFirestore;

    constructor(db: AngularFirestore, private afAuth: AngularFireAuth) {

        this.cameraFilter$ = new BehaviorSubject(null);
        this.dateFilter$ = new BehaviorSubject(null);
        this.db = db;

        afAuth.auth.onAuthStateChanged(user => {
            if (user) {
                this.retrieveEvents();
            } 
        });
 
    }

    
    retrieveEvents() {
        console.log("Signing up for events");
        this.events = combineLatest(
            this.cameraFilter$,
            this.dateFilter$
        ).pipe(
            switchMap(([camera, beforeDate]) =>
                this.db.collection('events', ref => {
                    let query: CollectionReference | Query = ref;
                    if (beforeDate) {query = query.where('startDatetime', '<=', beforeDate) };
                    if (camera) {query = query.where('camera', '==', camera) };
                    query = query.orderBy("startDatetime", "desc").limit(100)
                    return query;
                }).valueChanges()
            ));

            const eventsSubscription = this.events.subscribe({
                next(data) { console.log('Events updated: '); },
              });
    
    }

    filterByCamera(camera: string | null) {
        this.cameraFilter$.next(camera);
    }

    dateEvent(event: MatDatepickerInputEvent<Date>) {
        this.filterByBeforeDate(event.value);
    }

    filterByBeforeDate(beforeDate: Date | null) {
        this.dateFilter$.next(beforeDate);
    }

}
